
import { Storage } from '@ionic/storage';
import { comercioServicio } from './comercio.servicio';
import { Build } from '@stencil/core';

const store = new Storage();

export const APP_VERSION_NUMBER = '1.1.1';


export type FacingMode = "user" | "environment"

export enum StoreKeys {
    STORE_FICHAJE_AUTOMATICO_KEY_NAME = "es_fichaje_automatico",
    FACING_MODE = "facing_mode",
    COMERCIO = "comercio",
    EMPLEADOS = "empleados",
    DETECTION_TIMER = 'detection_timer',
    RECONOCIMIENTO_FACIAL = 'reconocimiento_facial',
    TOKEN = 'token',
    //LECTOR_DE_BARRAS = 'lector_de_barras'

}

export const DISTANCIA_THRESHOLD = 0.4
console.info("es build o no?!?!?", Build.isDev)
await store.create();
class PaxaposController {
    public readonly anchoFoto = 400;
    public readonly altoFoto = 400;
    public host = Build.isDev ? 'https://beta.paxapos.com':'https://beta.paxapos.com'
  //  public readonly host = 'https://a395584c-f1d8-4403-b48f-cfd416269ec2.mock.pstmn.io'

    public Store = store

    async storeClear() {
        return this.Store.clear()
    }

    async getUrl( path: string) {
        const comercio = await comercioServicio.getComercioActual()
        let host = this.host
        console.info('host',host);
        if ( comercio ) {
           host = this.host + "/"+ comercio.tenant
        }
        return host + path
    }


    async fetch(url: string, data: RequestInit = {}): Promise<any>{
        const urlpath = await this.getUrl(url)
        const headers = await this.getHeaders()
        const ndata = {...headers, ...data}
        const resp = await fetch( urlpath, ndata )
        const retdata = await resp.json()
        if ( ! resp.ok ) {
            throw new Error(retdata.name)
        }

        return retdata
    }

    async setDetectionTimer(dt: number): Promise<void>{
        return await this.Store.set(StoreKeys.DETECTION_TIMER, dt);
    }

    async getDetectionTimer(): Promise<number>{
        const token = await this.Store.get(StoreKeys.DETECTION_TIMER);
        return token || 1000
    }


  
    async setToken(token: string): Promise<void>{
        return await this.Store.set(StoreKeys.TOKEN, token);
    }

    async getToken(): Promise<string>{
        const token = await this.Store.get(StoreKeys.TOKEN);
        if ( !token ) {
            throw "Error token no fue setteado"
        }
        return token
    }
    

    async getHeaders( ) {
        const token = await this.getToken();
        const head ={
            headers: {
                "Accept": "application/json",
                "X-API-KEY": token,
                "X-Source-App": "com.paxapos.fichaje",
            }}; 
        return head
    }

    
}


export const paxapos = new PaxaposController()