
export interface iComercioResponse {
    groupSite: {
        GroupSite: iGroupSite;
        Site: iSites[]
    }
}


export interface iSites {
    name: string;
    alias: string;
}

export interface iGroupSite {
    host?: any;
    id: number;
    machine_uuid: string;
    name: string;
}

export class Comercio {
    id: number;
    name: string;
    host: string;
    machine_uuid: string;
    tenant?: string;
    siteName: string;

    constructor(comercioRes: iComercioResponse) {
        const gs = comercioRes.groupSite

        this.id = gs.GroupSite.id;
        this.name = gs.GroupSite.name
        this.host = gs.GroupSite.host
        this.machine_uuid = gs.GroupSite.machine_uuid
        this.tenant = gs.Site[0].alias
        this.siteName = gs.Site[0].name
    }

    toStore(): iComercioResponse{
        return {
            groupSite:{
                GroupSite: {
                    id: this.id,
                    name: this.name,
                    machine_uuid: this.machine_uuid,
                    host: this.host,
                },
                Site: [{
                    name: this.siteName,
                    alias: this.tenant,
                }]
            }
        }
    }
}