import { Comercio, iComercioResponse } from "../models/comercio";
import { notificacionServicio } from "./notificacion.servicio";
import { StoreKeys, paxapos } from "./paxapos";



export class ComercioServicio {
    
    public comercioActual: Comercio = null;


    async getComercioLink(path: string): Promise<string> {
        const comercio = await this.getComercioActual()
        
        if ( path && !path.startsWith("/") ) {
            path = `/${path}`
        }

        const host = comercio.host ? `https://${comercio.host}` : paxapos.host

        let url:string = `${host}/${comercio.tenant}`
        if ( path )  {
            url = url + path
        }

        return url
    }
    
    /**
     * Hello sirve para loguearse y dar inicio a la comunicacion entre el cliente y el servidor
     */
    async sendHello(): Promise<Comercio> {
        try {
            const comercioResponse = await paxapos.fetch('/fichaje/hello.json') as iComercioResponse
            const comercio = new Comercio(comercioResponse)
            await paxapos.Store.set(StoreKeys.COMERCIO, comercio.toStore())
            return comercio
        } catch (error) {
            notificacionServicio.error("Error de conexión con el servidor (hello)")
            console.error(error)
        }
    }



    public async setComercioActual(comercioActual: Comercio) {
        this.comercioActual = comercioActual;
        return await paxapos.Store.set(StoreKeys.COMERCIO, comercioActual.toStore())
    }

    public async getComercioActual(): Promise<Comercio> {
        const comercioActual = await paxapos.Store.get(StoreKeys.COMERCIO)

        return comercioActual ? new Comercio(comercioActual) : null
    }

    public async removeComercioActual() {
        return await paxapos.Store.remove(StoreKeys.COMERCIO)
    }

    public async hasComercio(): Promise<boolean> {
        const comercio = this.getComercioActual()
        return !!comercio;
    }
}


export const comercioServicio = new ComercioServicio();