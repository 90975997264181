import Swal, { SweetAlertIcon } from "sweetalert2";

export class NotificacionServicio {

    defaultConfig: any = {
        message: null,
        duration: 6000,
        position: 'bottom',
        showCloseButton: false,
        closeButtonText: 'X',
        cssClass: 'toast-default',
        dismissOnPageChange: false
    };


    public async  toast(msj: string, title?: string, icon: SweetAlertIcon = 'success') {
        const Toast = Swal.mixin({
          //  toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })
          
          return await Toast.fire({
            icon: icon,
            title: title,
            text: msj
          })
    }

    
    public async loadingOpen() {
        return await Swal.fire({
        title: 'Cargando',
        timerProgressBar: true,
        });
    
    }

    public async loadingClose() {
        Swal.close()
    }



    public async advertencia(msj: string, title?: string) {
        return this.toast(msj, title, 'warning')
        
    }

    public async exito(msj: string, title?: string, blobFile: Blob = null) {
        if ( blobFile ) {
            const url = URL.createObjectURL(blobFile)
            return await Swal.fire({
                title: title,
                text: msj,
                imageUrl: url,
                imageWidth: 200,
                imageHeight: 200,
                imageAlt: title,
                showConfirmButton: false,
                timer: 3000,
                position: 'center',
                timerProgressBar: true,
              })
        } else {
            return await this.toast(msj, title, 'success')
        }

    }

    public async error(msj?: string, title?: string) {
        return this.toast(msj, title, 'error')

    }

    public async info(msj: string, title?: string) {
        return this.toast(msj, title, 'info')
    }

}


export const notificacionServicio = new NotificacionServicio()